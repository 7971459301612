import '@fontsource/roboto';
import '@fontsource/roboto-mono';
import '@/styles/global/bio-sans.css';
import '@/styles/global/globals.css';
import '@/styles/global/swiper.css';
import '@/styles/global/highlight.css';
import type { NextWebVitalsMetric } from 'next/app';

import { analytics } from '@/lib/analytics/analytics';

export function reportWebVitals(metric: NextWebVitalsMetric) {
  analytics.track(metric.name, {
    event_category:
      metric.label === 'web-vital' ? 'Web Vitals' : 'Next.js custom metric',
    value: Math.round(
      metric.name === 'CLS' ? metric.value * 1000 : metric.value
    ), // values must be integers
    event_label: metric.id, // id unique to current page load
    non_interaction: true, // avoids affecting bounce rate.
  });
}

/* During development here are times where we may want to
 * be able to mock APIs that are not yet present in our
 * backend. This allows us to do so by spinning up MSW in our
 * dev environment or where the proper ENV_VARIABLE is set.
 */
// removing to test bundling issue
if (
  process.env.NEXT_PUBLIC_API_MOCKING === 'enabled' &&
  process.env.NODE_ENV === 'development'
) {
  //don't enable msw when cypress test
  if (typeof window !== 'undefined' && !(window as any).Cypress) {
    require('../mocks');
  }
}

export { default } from '@/components/common/App';
