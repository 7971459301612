import { UpsellFeature } from '@/generated/graphql';
import { UPSELL, useAnalytics } from '@/lib/analytics';
import Image from '@/rollbar-ui/Image';

export const FeatureSpotlightCard = ({ className = '' }) => {
  const { track } = useAnalytics();
  return (
    <div
      className={`${className} flex flex-col gap-4 border-2 border-gray-200 p-6 rounded-lg bg-white`}
    >
      <div className="grid grid-cols-[256px_1fr] gap-x-6">
        <Image
          alt="Snooze animation"
          className="w-full"
          src="/images/snooze/snooze-animation.gif"
        />

        <div className="flex flex-col gap-4">
          <h2 className="font-bold text-gray-700 text-2xl">Snooze an item</h2>

          <p className="text-gray-500 text-sm">
            Snoozing helps you manage your items by stopping notifications, and
            continuing to process occurrences.{' '}
            <a
              href={'https://docs.rollbar.com/docs/item-snooze'}
              target="_blank"
              className="text-blue-600 text-sm underline hover:text-blue-700 focus:text-blue-700"
              onClick={() =>
                track(UPSELL.MORE_INFO, { featureName: UpsellFeature.Snooze })
              }
            >
              Learn more ↗
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};
