import { useRouter } from 'next/router';
import { useEffect } from 'react';

import { useAccountInfo } from '@/contexts/AccountInfoContext';
import { useAnalytics } from '@/lib/analytics';

export function Analytics() {
  const router = useRouter();
  const analytics = useAnalytics();
  const accountInfo = useAccountInfo();
  useEffect(() => {
    analytics.page({
      route: router.route,
    });

    if (accountInfo) {
      const { user } = accountInfo;
      analytics.identify(user.id.toString());
      analytics.group(accountInfo.accountSlug, {
        accountId: accountInfo.accountId,
        accountSlug: accountInfo.accountSlug,
        planId: accountInfo.planId,
        subscribedPlan: accountInfo.subscribedPlan,
      });
    }

    router.events.on('routeChangeComplete', () => analytics.page());

    return () => {
      router.events.off('routeChangeComplete', () => analytics.page());
    };
  }, [accountInfo, analytics, router.events, router.route]);

  return null;
}
