import Button from '@/rollbar-ui/Button';

export function DefaultErrorPage() {
  return (
    <div className="flex flex-col items-center p-4">
      <img
        alt="Reload page"
        src="/images/sample_error/general-error-state.svg"
        className="p-8"
      />
      <p className="font-bold">It&apos;s not you, it&apos;s us :(</p>
      <p>This is embarassing, something could not be loaded correctly.</p>
      <p>
        If trying again doesn&apos;t fix it,{' '}
        <a className="text-blue-700" href="https://rollbar.com/support/">
          please contact support.
        </a>
      </p>
      <div className="flex flex-row justify-between p-8">
        <Button variant="secondary" onClick={() => window.location.reload()}>
          <img
            alt="Reload page"
            src="/images/sample_error/general-error-state-button.svg"
            className="pr-2"
          />
          Reload Page
        </Button>
      </div>
    </div>
  );
}
