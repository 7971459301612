import { createContext, useContext } from 'react';

import { UpsellContext } from './types';

const context = createContext<UpsellContext>({
  setShowModal: () => {},
  showModal: false,
});

const useUpsell = () => {
  const { showModal, setShowModal } = useContext(context);

  return {
    context,
    setShowModal,
    showModal,
  };
};

export default useUpsell;
