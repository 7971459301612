import { EnvelopeIcon } from '@heroicons/react/24/solid';
import Link from 'next/link';
import { FunctionComponent, useCallback, useEffect, useState } from 'react';

import { gqlClient } from '@/api/gqlClient';
import { useAccountInfo } from '@/contexts/AccountInfoContext';
import {
  UpsellFeature,
  useAccountInitiateUpsellMutation,
} from '@/generated/graphql';
import { UPSELL, useAnalytics } from '@/lib/analytics';
import { formatDollar } from '@/lib/format';
import { useComparePlansUrl } from '@/lib/urls';
import Button from '@/rollbar-ui/Button';
import { useToasts } from '@/rollbar-ui/contexts/ToastsContext';
import Tooltip from '@/rollbar-ui/Tooltip';

import ErrorMessage from '../ErrorMessage';

import { UpgradePlanCardFeatureList } from './UpgradePlanCardFeatureList';
import { getUpgradePlanById } from './util';

type Props = {
  className?: string;
  testId?: string;
  onClose: () => void;
};

const SuccessfulInitiateUpsellToast = () => (
  <div>
    <p>
      <b>Upgrade request sent successfully</b>
    </p>
    <p>
      Your upgrade request has been forwarded to the owners of your account.
    </p>
  </div>
);

export const UpgradePlanCard: FunctionComponent<Props> = ({ onClose }) => {
  const { track } = useAnalytics();
  const [plan, setPlan] = useState<{ monthly: string; name: string }>();

  const accountInfo = useAccountInfo();
  const { addToast } = useToasts();

  const surfaceErrors = () => {
    addToast({
      variant: 'error',
      content: (
        <ErrorMessage
          description="Upgrade request failed to send"
          errors={[
            'An unexpected error occurred while attempting to submit your upgrade reqest. Please try again.',
          ]}
        />
      ),
    });
  };
  const { mutate: initiateUpsellRequest, isLoading: isInitiatingUpsell } =
    useAccountInitiateUpsellMutation(gqlClient(), {
      onSuccess: (res) => {
        const { success } = res.accountInitiateUpsellRequest;

        if (success) {
          addToast({
            content: <SuccessfulInitiateUpsellToast />,
            variant: 'success',
          });
        } else {
          surfaceErrors();
        }
      },
      onError: surfaceErrors,
      onSettled: onClose,
    });

  const {
    accountId,
    accountSlug,
    planId,
    user: { isOwner },
  } = accountInfo;

  useEffect(() => {
    const plan = getUpgradePlanById({ id: planId });

    if (!plan) return;
    const { monthlyCost, name } = plan;

    setPlan({
      monthly: formatDollar(monthlyCost / 100, 2),
      name: name.split(' ')[0],
    });
  }, [planId]);

  const handleUpgradeRequest = useCallback(() => {
    track(UPSELL.UPGRADE, {
      featureName: UpsellFeature.Snooze,
      type: 'non-owner',
    });
    initiateUpsellRequest({
      input: {
        accountId: accountId?.toString(),
        feature: UpsellFeature.Snooze,
      },
    });
  }, [track, initiateUpsellRequest, accountId]);

  const handleUpgrade = useCallback(() => {
    track(UPSELL.UPGRADE, { featureName: UpsellFeature.Snooze, type: 'owner' });
    onClose();
  }, [onClose, track]);

  const comparePlansPageLink = useComparePlansUrl();

  return (
    <div className="text-white flex flex-col gap-4 bg-blue-700 border-2 border-gray-200 p-6 rounded-lg">
      <h2 className="font-bold text-2xl">{plan?.name}</h2>

      <p className="text-sm">
        Streamline workflows & uncover advanced insights
      </p>

      {isOwner ? (
        <Link
          href={`${comparePlansPageLink}?feature=snooze&selectedPlan=essentials&referalPage=${encodeURIComponent(
            window.location.href
          )}`}
          onClick={handleUpgrade}
        >
          <Button className="text-center w-full" variant="inverted-primary">
            Upgrade Now
          </Button>
        </Link>
      ) : (
        <Tooltip
          label={
            <p>
              Rollbar will email an upgrade request <br />
              to all account owners
            </p>
          }
          backgroundColor="gray"
        >
          <Button
            className="text-center gap-x-1 !text-sm w-full"
            onClick={handleUpgradeRequest}
            variant="inverted-primary"
            disabled={isInitiatingUpsell}
          >
            Submit upgrade request <EnvelopeIcon className="w-4" />
          </Button>
        </Tooltip>
      )}

      <UpgradePlanCardFeatureList
        onClose={onClose}
        upsellFeature={UpsellFeature.Snooze}
      />
      <span className="flex self-end gap-x-2 items-end">
        <span className="text-xl text-white font-semibold">
          {plan?.monthly}
        </span>

        <span className="text-sm text-white pb-[3px]">per month</span>
      </span>
    </div>
  );
};
