import { FunctionComponent } from 'react';

import { CurrentPlanCard } from '@/components/common/CurrentPlanCard';
import Modal from '@/rollbar-ui/components/Modal/Modal';

import { FeatureSpotlightCard } from './FeatureSpotlightCard';
import { UpgradePlanCard } from './UpgradePlanCard';

type Props = {
  onClose: () => void;
};

const UpsellModalTitle = () => (
  <h1 className="text-blue-700 text-2xl">
    Get the Most Out of Rollbar with Premium Tools
  </h1>
);

const UpsellModal: FunctionComponent<Props> = ({ onClose }) => (
  <Modal
    hasCloseButton={true}
    isOpen={true}
    onCancel={onClose}
    title={<UpsellModalTitle />}
    modalClassName="!max-w-4xl bg-gray-100"
    showFooter={false}
  >
    <div className="flex flex-col gap-y-6">
      <FeatureSpotlightCard className="hidden sm:flex" />

      <div className="gap-x-6 grid sm:grid-cols-2">
        <CurrentPlanCard className="hidden sm:flex" />

        <UpgradePlanCard onClose={onClose} />
      </div>
    </div>
  </Modal>
);

export { UpsellModal };
