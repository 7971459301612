import { useMemo, ImgHTMLAttributes } from 'react';

const Image = (props: ImgHTMLAttributes<HTMLImageElement>) => {
  // Memoizing the img tag's src attribute will improve the
  // rendering performance of the table icons
  return useMemo(() => {
    return <img alt="" {...props} />;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.src]);
};

export default Image;
